
//https://coolors.co/000000-14213d-fca311-e5e5e5-ffffff
$color_black: #000000;
$color_oxford_blue: #3C64B9;
$color_orange_web: #FCA311;
$color_platinum: #E5E5E5;
$color_white: #FFFFFF;

.yv-container
{
  display: grid;
  grid-template-rows: [first] 1fr [numbers-start] repeat(12, 1fr);
  
  justify-content: center;
  gap: 0.2em;

//  width: 100vw;

  
  .yv-item {

  }

  .yv-label {
    grid-row: first / span 1;
    justify-self: center;
    align-self: center;
    font-weight: bold;
  }
  
  @for $i from 0 through 12 {
    .yv-month-#{$i} {
      grid-column: #{$i + 1} / span 1;
    }
  }
  
  @for $i from 0 through 31 {
    .yv-day-#{$i} {
      grid-row: #{$i + 1} / span 1;
    }
  }
  
}


.day-button {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 4px;
  cursor: pointer;

  background-color: transparent;
  border: 1px solid $color_oxford_blue;
  color: $color_oxford_blue;

  &:disabled {
    color: $color_platinum;
    border: 1px solid $color_platinum;
    cursor: unset;
  }

  &.in-past {
    &.done {
      background-color: $color_oxford_blue;
      color: $color_white;
    }

    &:not(.done) {
      &:hover {
        border: 1px solid lighten($color_oxford_blue, 30%);
        background-color: lighten($color_oxford_blue, 30%);
        color: $color_white;
      }
    }
  }

  &.is-today {
    border: 1px solid $color_orange_web;
    color: $color_orange_web;
    font-weight: bold;

    &.done {
      background-color: $color_orange_web;
      color: $color_white;
    }

    &:not(.done) {
      &:hover {
        border: 1px solid lighten($color_orange_web, 30%);
        background-color: lighten($color_orange_web, 30%);
        color: $color_white;
      }
    }
  }
}